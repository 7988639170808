<template>
  <div class="header">
    <div class="header-center">
      <div class="header-box">
        <div class="box-left">
          <img src="@/assets/bg_img/logo.png" v-if="$route.path == '/'" alt="" />
          <img src="@/assets/bg_img/logo.png" v-else alt="" />
        </div>
        <div class="home-right" v-if="$route.path == '/'">
          <div class="right-title">圖書館主頁 | 館長信箱</div>
        </div>
        <div class="box-right" v-else>
          <div class="right-title">圖書館主頁 | 館長信箱</div>
          <div class="right-input">
            <input
              type="text"
              v-model="inputInfo"
              maxlength="15"
              placeholder="請輸入古籍關鍵字"
            />
            <div class="input-btn" @click="search()">搜索</div>
          </div>
        </div>
      </div>
      <div :class="[activeClass, errorClass]" ref="nav">
        <span class="nav-item" @click="skip('1')">網站首頁</span>
        <span class="nav-item" @click="skip('2')">國家珍貴古籍</span>
        <span class="nav-item" @click="skip('3')">河南省珍貴古籍</span>
        <span class="nav-item" @click="skip('4')">古籍資源</span>
        <span class="nav-item" @click="skip('5')">古籍知識</span>
        <span class="nav-item" @click="skip('6')">古籍修復</span>
        <span class="nav-item" @click="skip('7')">古籍著者名錄</span>
        <span class="nav-item" @click="skip('8')">傳承發展</span>
        <span class="nav-item" @click="skip('9')">館內動態</span>
      </div>
      <div class="pageTitle" v-if="titleUrl">
        <img :src="comState" alt="" />
      </div>
      <div class="pageCopyTitle" v-if="booksTitle">
        <div class="copyLeft">
          <img :src="CopyTitle" alt="">
        </div>
        <div class="copyright">
          <img :src="titleIconUrl" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    titleUrl: String,
    booksTitle:String,
    titleIcon:String,
  },
  data() {
    return {
      inputInfo: "", // 输入框信息
      home: true,
    };
  },
  methods: {
    search() {
      console.log(this.inputInfo, "搜索信息");
      this.inputInfo = "";
    },
    skip(val) {
      var skipInfo = "";
      switch (val) {
        case "1":
          skipInfo = "/";
          break;
        case "2":
          skipInfo = "/countryBooks";
          break;
        case "3":
          skipInfo = "/provinceBooks";
          break;
        case "4":
          skipInfo = "/booksResource";
          break;
        case "5":
          skipInfo = "/booksKnow";
          break;
        case "6":
          skipInfo = "/booksRepair";
          break;
        case "7":
          skipInfo = "/booksAuthorRecord";
          break;
        case "8":
          skipInfo = "/booksinherit";
          break;
        case "9":
          skipInfo = "/bookstrends";
          break;
      }
      console.log(this.$route.path);
      if (this.$route.path == skipInfo) return;
      this.$router.push(skipInfo);
    },
  },
  computed: {
    comState() {
      let obj = {
        1: require("@/assets/icon/guojia.png"),
        2: require("@/assets/icon/sheng.png"),
        3: require("@/assets/icon/zhishi.png"),
        4: require("@/assets/icon/xiufu.png"),
        5: require("@/assets/icon/chuancheng.png"), 
        6: require("@/assets/icon/dongtai.png"),
      };
      return obj[this.titleUrl];
    },
    CopyTitle(){
      let obj = {
        1: require("@/assets/icon/ziyuan.png"),
        2: require("@/assets/icon/minglu.png"),
      };
      return obj[this.booksTitle];
    },
    titleIconUrl(){
      let obj = {
        0: require("@/assets/icon/jingbu.png"),
        1: require("@/assets/icon/shibu.png"),
        2: require("@/assets/icon/zibu.png"),
        3: require("@/assets/icon/jibu.png"),
        4: require("@/assets/icon/congbu.png"),
        5: require("@/assets/icon/beitie.png"),
        6: require("@/assets/icon/songdai.png"),
        7: require("@/assets/icon/yuandai.png"),
        8: require("@/assets/icon/mingdai.png"),
        9: require("@/assets/icon/qingdai.png"),
      };
        return obj[this.titleIcon];
    },
    activeClass() {
      return "nav";
    },
    errorClass() {
      return this.$route.path == "/" ? "" : "itemColor";
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.header {
  z-index: 9999;
  width: 100%;
  // height: 270px;
  box-sizing: border-box;
  padding-top: 30px;
  // background: url(../assets/bg_img/banner.jpg) no-repeat;
  background-size: 100% 80%;
  .header-center {
    width: 85%;
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    .nav {
      width: 100%;
      height: 45px;
      // background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-weight: bold;
      color: #fff;
      .nav-item {
        font-size: 22px;
        height: 100%;
        line-height: 45px;
        padding: 0px 15px;
        cursor: pointer;
        letter-spacing: 1pt;
        &:hover {
          transition: all 0.5s;
          color: #fff;
          border-radius: 7px;
          background-color: #646464;
        }
      }
    }
    .header-box {
      width: 100%;
      height: 290px;
      display: flex;
      justify-content: space-between;
      .box-left {
        width: 15%;
        padding-left: 120px;
        height: 92%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .box-right {
        width: 530px;
        height: 100%;
        font-size: 18px;
        font-weight: bold;
        padding-top: 30px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .right-input {
          width: 530px;
          height: 42px;
          line-height: 42px;
          margin-top: 50px;
          display: flex;
          font-size: 20px;
          // border: 1px #ddd solid;
          input {
            width: 430px;
            height: 40px;
            font-size: 18px;
            text-indent: 1em;
            color: #646464;
            border: 1px #ddd solid;
            border-right: transparent;
            outline: none; /* 移除默认的轮廓线 */
            // border-color: transparent; /* 可以设置边框颜色为透明或你想要的颜色 */
            box-shadow: none; /* 移除默认的阴影效果 */
          }
          .input-btn {
            background-color: #646464;
            width: 100px;
            height: 41.5px;
            color: #fff;
            font-weight: 400;
            cursor: pointer;
          }
        }
      }
      .right-title {
          text-align: right;
          cursor: pointer; 
        }
      .home-right {
        width: 500px;
        height: 100%;
        padding-top: 100px;
        text-align: right;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
      }
    }
    .pageTitle {
      width: 300px;
      height: 70px;
      margin: 40px auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .pageCopyTitle{
      width: 100%;
      height: 80px;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      .copyLeft{
        width: 300px;
        height: 70px;
      }
      .copyright{
        // background-color: cyan;
        padding-top: 10px;
        flex-grow: 1; /* 占据剩余空间 */
      }
    }
  }
}
.itemColor {
  color: #646464 !important;
}
</style>
