<template>
  <div
    class="homePage"
    ref="backgroundContainer"
  >
  <hello-world  />
  <img :src="imgUrl" alt="">
    
    <div class="box-input">
      <input
        type="text"
        v-model="inputInfo"
        maxlength="15"
        placeholder="請輸入古籍關鍵字"
      />
      <div class="input-btn" @click="search()">搜索</div>
    </div>
    <public-foot :FontColor="fontColor"/>
  </div>
</template>

<script>
import HelloWorld from "@/components/pageHeader.vue";
import PublicFoot from "@/components/publicFoot.vue";
export default {
  name: "HomeView",
  components: {
    HelloWorld,
    PublicFoot
  },
  data() {
    return {
      inputInfo: "", // 输入框信息
      imgUrl: require("@/assets/bg_img/banner1.jpg"), // 初始图片URL
      images: [
        require("@/assets/bg_img/banner1.jpg"),
        require("@/assets/bg_img/banner2.jpg"),
        require("@/assets/bg_img/banner3.jpg"),
        require("@/assets/bg_img/banner4.jpg"),
        require("@/assets/bg_img/banner5.jpg"),
      ],
      currentImageIndex: 0, // 当前图片索引
      intervalId: null, // 定时器ID
      fontColor:"#fff"
    };
  },
  methods: {
    search() {
      console.log(this.inputInfo, "搜索信息");
      if(this.inputInfo){
        this.$message({
          showClose: true,
          message: '未搜索到您要的内容'
        });
        this.inputInfo = "";
      }
    },
    startBackgroundSwitch() {
      this.intervalId = setInterval(() => {
        this.currentImageIndex =
          (this.currentImageIndex + 1) % this.images.length;
           this.imgUrl = this.images[this.currentImageIndex];
      }, 7000); // 每3秒切换图片
    },
  },
  mounted() {
    this.startBackgroundSwitch();
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
};
</script>

<style lang="scss" scoped>
@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  15% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  27% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.homePage {
  width: 100vw;
  height: 100vh;
  img {
    z-index: -1;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    animation: imageAnimation 7s ease-in-out infinite 0s;
  }
  .box-input {
    margin: 100px auto;
    width: 750px;
    height: 40px;
    line-height: 40px;
    display: flex;
    input {
      width: 630px;
      height: 36px;
      font-size: 18px;
      text-indent: 1em;
      color: #646464;
      outline: none; /* 移除默认的轮廓线 */
      border-color: transparent; /* 可以设置边框颜色为透明或你想要的颜色 */
      box-shadow: none; /* 移除默认的阴影效果 */
    }
    .input-btn {
      background-color: #646464;
      width: 100px;
      height: 38px;
      color: #fff;
      font-size: 20px;
      font-weight: 400;
      cursor: pointer;
      border: 1px #fff solid;
    }
  }
 
}
</style>
