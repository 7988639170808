import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/countryBooks',
    name: 'countryBooks', //国家珍贵古籍
    component: () => import(/* webpackChunkName: "about" */ '../views/page/countryBooks/index.vue')
  },
  {
    path: '/provinceBooks',
    name: 'provinceBooks', // 省珍贵古籍
    component: () => import(/* webpackChunkName: "about" */ '../views/page/provinceBooks/index.vue')
  },
  {
    path: '/booksResource',
    name: 'booksResource', // 古籍资源
    component: () => import(/* webpackChunkName: "about" */ '../views/page/booksResource/index.vue')
  },
  {
    path: '/booksKnow',
    name: 'booksKnow', // 古籍知识
    component: () => import(/* webpackChunkName: "about" */ '../views/page/booksKnow/index.vue')
  },
  {
    path: '/booksRepair',
    name: 'booksRepair', // 古籍修复
    component: () => import(/* webpackChunkName: "about" */ '../views/page/booksRepair/index.vue')
  },
  {
    path: '/booksAuthorRecord',
    name: 'booksAuthorRecord', // 古籍著者名录
    component: () => import(/* webpackChunkName: "about" */ '../views/page/booksAuthorRecord/index.vue')
  },
  {
    path: '/booksinherit',
    name: 'booksinherit', // 古籍传承
    component: () => import(/* webpackChunkName: "about" */ '../views/page/booksinherit/index.vue')
  },
  {
    path: '/bookstrends',
    name: 'bookstrends', // 管内动态
    component: () => import(/* webpackChunkName: "about" */ '../views/page/bookstrends/index.vue')
  },
]

const router = new VueRouter({
  mode: 'hash', // history 历史模式 hash 哈希模式
  base: process.env.BASE_URL,
  routes
})

export default router
